// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


// import required modules
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import { Carousel } from 'react-bootstrap';
import { useState } from 'react';
import ContactUsButton from '../../common/ContactUsButton';

const HomeSection8 = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <div>
      {/* <Swiper
        // install Swiper modules
        spaceBetween={30}
        // effect={'fade'}
        navigation={true}
        // pagination={{
        //   clickable: true,
        // }}
        modules={[EffectFade, Navigation, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img className='slide_img' src="/images/inner-banner-1.jpg" alt='img' />
        </SwiperSlide>
        <SwiperSlide>
          <img className='slide_img' src="/images/inner-banner-2.jpg" alt='img' />
        </SwiperSlide>
        <SwiperSlide>
          <img className='slide_img' src="/images/inner-banner-3.jpg" alt='img' />
        </SwiperSlide>
        <SwiperSlide>
          <img className='slide_img' src="/images/inner-banner-1.jpg" alt='img' />
        </SwiperSlide>
      </Swiper> */}
      <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
          <img className='slide_img' src="/images/inner-banner-4.jpg" alt='img' />
          {/* <ExampleCarouselImage text="First slide" /> */}
          <Carousel.Caption className='slider_content'>
            {/* <div className="  text-white"> */}
              <div className=" mx-auto ">
                <h2 className='title-xl fw-bold mb-3 text-white'>Effortlessly Blend Comfort & Style!</h2>
                {/* <p>Effortlessly blend comfort and style with our Casual & Everyday collection, featuring cozy sweaters, versatile denim, laid-back tees, and relaxed-fit joggers for your everyday adventures</p> */}
                <ContactUsButton />
              </div>
            {/* </div> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          {/* <ExampleCarouselImage text="Second slide" /> */}
          <img className='slide_img' src="/images/inner-banner-5.jpg" alt='img' />
          <Carousel.Caption>
              <div className=" mx-auto ">
                <h2 className='title-xl fw-bold mb-3 text-white'>Effortlessly Blend Comfort & Style2</h2>
                {/* <p>Effortlessly blend comfort and style with our Casual & Everyday collection, featuring cozy sweaters, versatile denim, laid-back tees, and relaxed-fit joggers for your everyday adventures</p> */}
                <ContactUsButton />
              </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          {/* <ExampleCarouselImage text="Third slide" /> */}
          <img className='slide_img' src="/images/inner-banner-3.jpg" alt='img' />
          <Carousel.Caption>
              <div className=" mx-auto">
                <h2 className='title-xl fw-bold mb-3 text-white'>Effortlessly Blend Comfort & Style3</h2>
                {/* <p>Effortlessly blend comfort and style with our Casual & Everyday collection, featuring cozy sweaters, versatile denim, laid-back tees, and relaxed-fit joggers for your everyday adventures</p> */}
                <ContactUsButton />
              </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          {/* <ExampleCarouselImage text="Third slide" /> */}
          <img className='slide_img' src="/images/inner-banner-1.jpg" alt='img' />
          <Carousel.Caption>
              <div className=" mx-auto">
                <h2 className='title-xl fw-bold mb-3 text-white'>Effortlessly Blend Comfort & Style4</h2>
                {/* <p>Effortlessly blend comfort and style with our Casual & Everyday collection, featuring cozy sweaters, versatile denim, laid-back tees, and relaxed-fit joggers for your everyday adventures</p> */}
                <ContactUsButton />
              </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          {/* <ExampleCarouselImage text="Third slide" /> */}
          <img className='slide_img' src="/images/inner-banner-2.jpg" alt='img' />
          <Carousel.Caption>
              <div className=" mx-auto">
                <h2 className='title-xl fw-bold mb-3 text-white'>Effortlessly Blend Comfort & Style5</h2>
                {/* <p>Effortlessly blend comfort and style with our Casual & Everyday collection, featuring cozy sweaters, versatile denim, laid-back tees, and relaxed-fit joggers for your everyday adventures</p> */}
                <ContactUsButton />
              </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

    </div>
  )
}

export default HomeSection8