import InnerBanner from '../components/common/InnerBanner'
import MyForm from './contactForm';

const ContactUS = () => {

    return (
        <>
            <InnerBanner
                title={'Contact Us'}
                info={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam dignissim sapien at fringilla malesuada. Donec fringilla varius feugiat. Morbi et congue arcu. Mauris quis ultricies odio, at lacinia libero. '}
            />
            {/* ======New Section Start ====== */}
            <section className={`sectionPadding`}>
                <div className="container">
                    <div className={`row align-items-center`}>
                        <div className="col-md-5">
                            <h2 className='title-xl fw-bold'>Get in touch</h2>
                            <p>Let us know what challenge you are facing, and we are ready to provide a solution. Connect with us here.</p>
                            {/* <div className="">
                                <ul className="contact_usInfo ps-0">
                                    <li>
                                        <i className="fa-solid fa-location-dot me-3 mt-1"></i>
                                        <div>
                                            <h5>Corporate Head Office</h5>
                                            <p>HSIIDC IT Park, Plot No 6, 7th Floor, Behind Gurudwara Nada Sahib, Sector 22, Panchkula, Haryana 134109</p>
                                        </div>
                                    </li>
                                    <li>
                                        <i className="fa-solid fa-location-dot me-3 mt-1"></i>
                                        <div>
                                            <h5>Corporate Office Chandigarh</h5>
                                            <p>SCO 85-86, 1st Floor, near PESCO Office, Sector 34A, Sector 34, Chandigarh, 160022</p>
                                        </div>
                                    </li>
                                    <li>
                                        <i className="fa-solid fa-phone me-3 mt-1"></i>
                                        <p><a href="tel:+91-6283857196" title="+91-6283857196">+91-6283857196</a>, <a href="tel:+91-7717505073" title="+91-7717505073">+91-7717505073</a></p>
                                    </li>
                                    <li>
                                        <i className="fa-solid fa-envelope me-3 mt-1"></i>
                                        <a href="mailto:info@v2collection.com" title="info@v2collection.com">info@v2collection.com</a>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                        <div className="col-md-7">
                            <MyForm />
                        </div>
                    </div>
                </div>
            </section>
            <section className='mapSection'>
                <iframe title='place' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3432.179185777595!2d76.86394477607318!3d30.65708238928398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f94f470b5981d%3A0xe0550cd1860e6873!2sEmerging%20Heights%20II!5e0!3m2!1sen!2sin!4v1720686653443!5m2!1sen!2sin" width="100%" height="400" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </section>
        </>
    );
};

export default ContactUS;
