import React from 'react'
import { Link } from 'react-router-dom';
import { FiPhoneCall } from "react-icons/fi";



function ContactUsButton() {
  return (
    <Link to={'/'} className='mt-2 dBtn btnSecondary shopbtn hasShadow'><span className='ms-2'>Shop Now</span></Link>
  )
}
export default ContactUsButton
