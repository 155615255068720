
// import aboutSectionImage from './../../../assets/Images/homepage/aboutImage.png';
// import { Button, Card } from 'react-bootstrap'
import shopingCardData from '../../data/shopingCardData.json';

const HomeSection2 = () => {
    const cardData = shopingCardData.shopingCard;
    return (
        <section className="sectionPadding homepage-slider">
            <div className="container">
                <div className="row align-items-center">
                    {/* <div className="col-lg-6" data-aos="fade-right" data-aos-duration="1500">
                    <h4 className='section-title-sm'>Amplify your Sales in 90 Days</h4>
                    <h2 className='title-xl fw-bold'>Digital Marketing Company Helping Businesses to Augment their Online Presence</h2>
                    <p>We leave no leaf unturned when it comes to website optimization, enabling it to rank higher in search engines such as Google. We conduct in-depth competitor research in order to produce effective results within the committed time frame and budget. Each SEO activity is targeted to ensure that your website ranks high in organic searches.</p>

                </div>
                <div className="col-lg-6 text-center" data-aos-delay="400" data-aos="fade-left" data-aos-duration="1500">
                    <img src={aboutSectionImage} alt="Amplify your Sales in 90 Days"></img>
                </div> */}
                    <div className="col-lg-12 d-flex gap-4 flex-wrap justify-content-center">
                        {cardData.map((card) => {
                            return (
                                <div key={card.id} className='shop_card'>
                                    <img className='card_image' src={card.productImage} alt='card' />
                                    <div className='text-center'>
                                        <p className='category_name'>{card.category}</p>
                                        <p className='card_title'>{card.title}</p>
                                        <p className='price'>${card.price}</p>
                                        <div className='size_btn'>
                                            <p>S</p>
                                            <p>M</p>
                                            <p>L</p>
                                            <p>XL</p>
                                            <p>XXL</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                </div>
            </div>
        </section>

    );
};

export default HomeSection2;