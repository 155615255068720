import './footerStyle.scss';
import Logo from '../common/SiteLogo.jsx';
import { NavLink } from "react-router-dom";
import SocialLinks from '../common/SocialLinks.jsx'

const Footer = () => {

    const handleNavLinkClick = () => {
        window.scrollTo(0, 0);
    };

    const topLinks = [
        {
            id:1,
            title: 'Home',
            link: '/'  // Correctly set the home page route
        },
        {
            id:2,
            title: 'New Arrivals',
            link: '/newarrivals'
        },
        {
            id:5,
            title: "Women's",
            link: '/women'
        },
        {
            id:7,
            title: 'Sales',
            link: '/sales'
        },
        {
            title: 'Contact',
            link: '/contact'
        },
        // Add more service sections as needed
    ];


    const serviceLinks = [
        {
            id:1,
            title: 'Shirt',
            link: 'service/shirts'  // Correctly set the home page route
        },
        {
            id:2,
            title: 'T-Shirt',
            link: 'service/tshirts'
        },
        {
            id:3,
            title: 'Jeans',
            link: 'service/jeans'
        },
        {
            id:4,
            title: 'Formals',
            link: 'service/formals'
        },
        // Add more service sections as needed
    ];

    return (
        <footer className='siteFooter' >
            <div className="footerTopWrap">
                <div className="container">
                    <div className="row footerRow">
                        <div className="col-lg-4 col-md-6 pr-5">
                            <Logo src={"/images/v2collection-logo.svg"} maxWidth="140px" />
                            <p className='mt-4 mb-0'>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonsts without relying on meaningful content.</p>
                            <SocialLinks />
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <h2 className='title-sm fw-bold mb-4'>Top Links</h2>
                            <ul className="footerList linkList">
                                {topLinks.map((list, index) => (
                                    <li className={`serviceWrap`} key={index}>
                                        <NavLink onClick={() => handleNavLinkClick()} to={list.link}>{list.title}</NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h2 className='title-sm fw-bold mb-4'>Services</h2>
                            <ul className="footerList linkList">
                                {serviceLinks.map((list, index) => (
                                    <li className='serviceWrap' key={index}>
                                        <NavLink onClick={() => handleNavLinkClick()} to={list.link}>{list.title}</NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h2 className='title-sm fw-bold mb-4'>Get In Touch</h2>
                            <ul className="footerList contactInfo">
                                <li>
                                    <i className="fa-solid fa-phone"></i>
                                    <a href="tel:+91-6283857196" title="+91-6283857196">+91-7021323218</a>
                                </li>
                                <li>
                                    <i className="fa-solid fa-phone"></i>
                                    <a href="tel:+91-7717505073" title="+91-7717505073">+91-9784000000</a>
                                </li>
                                <li>
                                    <i className="fa-solid fa-envelope"></i>
                                    <a href="mailto:info@v2collection.com" title="info@v2collection.com">info@v2collection.com</a>
                                </li>
                                <li>
                                    <i className="fa-solid fa-location-dot"></i>
                                    <p>Peer Muchalla, Sector 20, Panchkula, Haryana 160104</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footerCopyright">
                <p className='mb-0'>Copyright © 2024 v2collection. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
