import React from 'react';
import { BiLogoLinkedin,BiLogoTwitter,BiLogoInstagramAlt } from 'react-icons/bi';


const SocialLinks = () => {

    const socialLinks = [
        // {
        //     title:'Upwork',
        //     link:'https://www.upwork.com/agencies/v2collection/',
        //     icon: BiLogoUpwork
        // },
        {
            title:'Facebook',
            link:'https://www.instagram.com/vibhu_saini777/',
            icon: BiLogoInstagramAlt 
        },
        {
            title:'Twitter',
            link:'#',
            icon: BiLogoTwitter
        },
        {
            title:'Linkedin',
            link:'https://www.linkedin.com/login',
            icon: BiLogoLinkedin
        },
        
    ];



    return (
        <ul className='socialMediaList'>
            {socialLinks.map((item, index) => (
                <li key={index}>
                    <a title={item.title} href={item.link}  target='_blank' rel='noreferrer'>
                        {item.icon && <item.icon />} {/* Render the icon component if it's provided */}
                    </a>
                </li>
            ))}
        </ul>
    );

};


export default SocialLinks;