import React from 'react'
import InnerBanner from './common/InnerBanner'

const Service = () => {
    return (
        <div>
            <InnerBanner title={"Men's"}/>
        </div>
    )
}

export default Service