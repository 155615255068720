import InnerBanner from '../components/common/InnerBanner'

const TopWorkMarketplaces = () => {
    return (
        <>
        <InnerBanner title={'Sales'} info={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam dignissim sapien at fringilla malesuada. Donec fringilla varius feugiat. Morbi et congue arcu. Mauris quis ultricies odio, at lacinia libero. '}/>
        </>
    );
};

export default TopWorkMarketplaces;
